<template>
<div class="container">
    <div class="row">
        <div class="col-md-10 offset-md-1">

 
  <div class="cal-wrap ">
    <h3>BMR Kalkulator</h3>
    <label for="height2"><b>Visina:</b> {{height2}}cm</label>
    <input
      type="range"
      class="slider col-4"
      name="height2"
      id="height2"
      min="100"
      max="220"
      v-model="height2"
      @input="calculateBMI()"
      @focus="fixed='height2'"
    />
    <label for="weight2"><b>Težina:</b> {{weight2}}kg</label>
    <input
      type="range"
      name="weight2"
      id="weight2"
      min="30"
      max="230"
      v-model="weight2"
      class="slider col-4 "
      @input="calculateBMI()"
      @focus="fixed='weight2'"
    />
    <label for="godiste"><b>Godina:</b> {{godiste}}</label>
    <input
      type="range"
      name="godiste"
      id="godiste"
      min="3"
      max="90"
      v-model="godiste"
      class="slider col-4 "
      @input="calculateBMI()"
      @focus="fixed='weight'"
    />
<div class="toggle-button toggle-button--valo">

<input type="radio" id="male" name="male" class="form-check-input" value="male"  @input="calculateBMI()" v-model="gender" />
<label  class="form-check-label" for="male">Musko</label>

<input type="radio" id="female" name="female" class="form-check-input" value="female"  @input="calculateBMI()" v-model="gender" />
<label  class="form-check-label" for="female">Zensko</label>
</div>


    <label for="bmi2" class="text-center rezultat">
      Dnevna energetska potreba:<br> <b>{{bmi2}} kCal</b><br></label>
    <input
      type="range"
      name="bmi2"
      id="bmi2"
      min="10"
      max="50"
      v-model="bmi2"
      class="slider col-4 bmi-slider"
      @input="calcSelected()"
    />
  </div>

         </div>
       
    </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      height2: 186,
      weight2: 78,
      bmi2: 1690,
    godiste: 21,
      gender: "male",
      fixed: "height"
      
    };
  },
  computed: {
    isBmiOkay() {


      return this.bmi2 < 18.5
        ? "underweight"
        : this.bmi2 < 24.9
        ? "normalan"
        : this.bmi2 < 29.9 
        ? "prekomeran"
        : this.bmi2 < 34.9
        ? "Gojaznost I stepena"
         : this.bmi2 < 40
        ? "Gojaznost II stepena"
         : this.bmi2 < 99
        ? "Gojaznost III stepena"
        : "normal";
    }
  },
  methods: {
    calculateBMI() {
    //   this.bmi =
    //     Math.round((this.weight * 10000 * 10) / (this.height * this.height)) /
    //     10;



        return  (this.gender === 'male')
        ? this.bmi2 =(703 + 9.56 * this.weight2 + 1.84 * this.height2 - 4.67 * this.godiste).toFixed(0)
        : this.bmi2 = (66.47 + 13.75 * this.weight2 + 5 * this.height2 - 6.75 * this.godiste).toFixed(0);
        
    },
    
    calcSelected() {
      if (this.fixed == "height2") {
        this.weight2 =
          Math.round(((this.height2 * this.height2 * this.bmi2) / 10000) * 10) /
          10;
      } else {
        this.height2 =
          Math.round(Math.sqrt((this.weight2 * 10000) / this.bmi2) * 10) / 10;
      }
    }
  }
};
</script>

<style scoped>
.radio-button,
.checkbox {
  position: relative;
  margin: 20px 0;
}
.checkbox { display: block; }

.radio-button input,
.checkbox input {
  position: absolute;
  margin: 5px;
  padding: 0;
  /* for mobile accessibility (iOS Label Bug) */
  visibility: hidden;
}
.radio-button .label-visible,
.checkbox .label-visible {
  margin-left: 2em;
  margin-bottom: 0;
}
.bmi-slider{display: none;}
.slider{width:100%;accent-color: #469c08;}.rezultat{font-size:16px;text-align: center;margin-top:10px}
.cal-wrap {
display: flex;
    padding: 20px;
    flex-direction: column;
    position: relative;
    /* width: 300px; */
    gap: 10px;
    height: 360px;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 20px 50px 0 rgb(0, 0, 0, 0.1);
}
@media only screen and (max-width: 480px) {
  .cal-wrap {
    height: 370px;
  }
}

.underweight {
  color: #dbb900;
}

.normalan {
  color: green;
}
.prekomeran {
  color:  #f38303;
}
[class*="Gojaznost"]{
      color: red;
}
.Gojaznost I stepena {
  color: red;
}

.bmi-desc {
  font-style: oblique;
}
span {
  height: 1rem;
  margin: 0;
}
input {
  /* height: 1.5rem; */
}
.toggle-button label{margin-left: 5px;}
.form-check-input[type=radio] {margin-left: 15px;}
</style>
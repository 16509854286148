import { createApp } from 'vue'
import App from './App.vue'

import {createRouter, createWebHistory} from 'vue-router'



import './assets/style.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"


// const router = createRouter({
//     history: createWebHistory(),
//     routes:[]
// });

// setup routes
const router = createRouter({
    scrollBehavior (to, from, savedPosition) {
        return {       el: '.uvod',top: 120,
    };
      },
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'Home',
        component: () => import('./views/TKView.vue')
      },
      {
        path: '/tk',
        name: 'Tablica kalorija',
        component: () => import('./views/TKView.vue')
      },
      {
        path: '/dpv',
        name: 'DPV',
        component: () => import('./views/VodaView.vue')
      },
      {
        path: '/aditivi',
        name: 'Aditivi u Hrani',
        component: () => import('./views/AditiviView.vue')
      },
      {
        path: '/bmi',
        name: 'bmi',
        component: () => import('./views/BMIView.vue')
      },
      {
        path: '/bmr',
        name: 'bmr',
        component: () => import('./views/BMRView.vue')
      },
      {
        path: '/kalorije',
        name: 'kalorije',
        component: () => import('./views/KalorijeView.vue')
      },
      {
        path: '/masti',
        name: 'masti',
        component: () => import('./views/MastiView.vue')
      },
      {
        path: '/proteini',
        name: 'proteini',
        component: () => import('./views/ProteiniView.vue')
      },
      {
        path: '/uh',
        name: 'uh',
        component: () => import('./views/UHView.vue')
      },
      {
        path: '/tdee',
        name: 'tdee',
        component: () => import('./views/TDEEView.vue')
      },
      {
        path: '/aditivi-list',
        name: 'AditivList',
        component: () => import('./components/AditivList.vue')

      },
      {
        path: '/aditivi/:id/:oznaka-:ime',
        name: 'aditiv-info',
        component: () => import('./views/AditivInfo.vue')
      },
 
    ]
  })

  // Dodajte head opciju
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  next();
});
const app= createApp(App)



// Globalne promenljive za podrazumevane vrednosti
// const defaultDescription = 'Aplikacija je uvek dostupna, pružajući vam korisne alate za održavanje zdravog i uravnoteženog načina života.';
// const defaultKeywords = 'tablica kalorija, nutritivna vrednost namirnica, nutritivna,vrednost,namirnica,kalorije,ugljeni hidrati, bmi, bmr, tdee, kalkulator, aditiv u ishrani, izbegni aditivi, konzervansi';


// app.config.globalProperties.$defaultDescription = defaultDescription;
// app.config.globalProperties.$defaultKeywords = defaultKeywords;

app.use(router)

app.mount('#app')



//createApp(App).mount('#app')

<template>
<div>
<HeaderNav />

<section class="uvod">

</section>


<router-view/>


<section class="fittpro-section bg-color-1">
  <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 mb-3 naslov text-center mt-5 mb-4">
          <h2>Potrebna vam je <span>Izrada Sajta? </span></h2>
          <p class="mt-5 mb-5"> 
            iskoristite prednosti internet medija kako bi unapredili svoje poslovanje, budite prisutni 24 sata dnevno i učvrstite svoju poziciju na svetskom web-u.

<br><br>Izrada web sajta će biti završena u roku 3 dana i u cenu paketa je uključeno sve neophodno kao i gratis domen i hosting u trajanju godinu dana.

          </p>
        </div> 
      </div>
  </div>
</section>

<section class="video-section">
  <div class="container">
      <div class="video-frame">
          <div class="video-btn">
              <a class="" target="_blank" href="https://bbx.rs/">
                <img alt="Zdrava Ishrana logo" src="@/assets/bblogo.png">

              </a>
          </div>
      </div>
  </div>
</section>




<section class="stajeto">
  <div class="tp-blog__area tp-blog__space">
    <div class="container">
        <div class="service-content-wrap">
          <div class="row">
            <div class="col-xl-3 col-sm-6">
               <router-link to="/kalorije">
              <div class="service-item service-01">
                  <div class="service-img">
                      <img src="./assets/kcal2.svg" alt="Kalorije">
                  </div>
                  <div class="service-content">
                      <h3 class="title"><a href="#">Šta su to <span>Kalorije?</span></a></h3>
                      <p>Kada jedemo i pijemo, unosimo energiju u svoje telo. Naša tela troše tu energiju...</p>
                      <p class="read_more">Opširnije <i class="fas fa-long-arrow-alt-right"></i></p>
                  </div>
              </div>
               </router-link>
            </div>
            <div class="col-xl-3 col-sm-6">
              <router-link to="/masti">

              <div class="service-item">
                  <div class="service-img">
                      <img src="./assets/masti.svg" alt="Masti">
                  </div>
                  <div class="service-content">
                    <h3 class="title"><a href="#">Šta su to masti?</a></h3>
                    <p>Masti su važan deo ishrane i služe kao izvor energije za organizam. Takođe imaju...</p>
                    <p class="read_more">Opširnije <i class="fas fa-long-arrow-alt-right"></i></p>
                  </div>
              </div>
              </router-link>
            </div>
            <div class="col-xl-3 col-sm-6">
               <router-link to="/proteini">
              <div class="service-item service-03">
              

                  <div class="service-img">
                      <img src="./assets/proteini.svg" alt="Proteini">
                  </div>
                  <div class="service-content">
                      <h3 class="title"><a href="#">Šta su to proteini?</a></h3>
                      <p>Proteini su jedan od osnovnih nutritivnih sastojaka koji se nalaze u hrani. Oni su važni...</p>
                      <p class="read_more">Opširnije <i class="fas fa-long-arrow-alt-right"></i></p>
                  </div>
              </div>
               </router-link>

            </div>
            <div class="col-xl-3 col-sm-6">
              <router-link to="/uh">
              <div class="service-item">
                  <div class="service-img">
                      <img src="./assets/uh.svg" alt="ugljeni hidrati">
                  </div>
                  <div class="service-content">
                      <h3 class="title"><a href="#">Šta su Ugljeni Hidrati?</a></h3>
                      <p>Ugljeni hidrati su su ključni izvor energije za naše telo.Ugljeni hidrati....</p>
                      <p class="read_more opsirnije">Opširnije <i class="fas fa-long-arrow-alt-right"></i></p>
                  </div>
              </div>
              </router-link>

            </div>
          </div>
        </div>
    </div>
  </div>
</section>

<SiteFooter />
</div>
</template>

<script setup>
import { ref } from 'vue'

import BMICalculator from "@/components/BMICalculator.vue";
import BMRCalculator from "@/components/BMRCalculator.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import SiteFooter from "@/components/SiteFooter.vue";

const isAddClass = ref(false);

const addClass =  () => {
  isAddClass.value = !isAddClass.value;
}

</script>
<style scoped>
.read_more {
    text-decoration: none;
    color: #469c08 !important;
    font-weight: 600;
}
</style>
<template>
<div class="container">
    <div class="row">
        <div class="col-md-10 offset-md-1">

 
  <div class="cal-wrap ">
    <h3>BMI Kalkulator</h3>
   
    <label for="height"><b>Visina:</b> {{height}}cm</label>
    <input
      type="range"
      class="slider col-4"
      id="height"
      name="height"
      min="100"
      max="220"
      v-model="height"
      @input="calculateBMI()"
      @focus="fixed='height'"
    />
   
    <label for="weight"><b>Težina:</b> {{weight}}kg:</label>
    <input
      type="range"
      name="weight"
      id="weight"
      min="30"
      max="230"
      v-model="weight"
      class="slider col-4 "
      @input="calculateBMI()"
      @focus="fixed='weight'"
    />
   
    <label for="bmi" class="text-center mt-5 rezultat">Vaš <b>BMI</b> je:<br> <b>{{bmi}}</b> <br>
      <span :class="isBmiOkay" class="bmi-desc">{{isBmiOkay}}</span>
    </label>

    <input
      type="range"
      name="bmi"
      id="bmi"
      min="10"
      max="50"
      v-model="bmi"
      class="slider col-4 bmi-slider"
      @input="calcSelected()"
    />
  </div>

         </div>
       
    </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      height: 180,
      weight: 80,
      bmi: 24.7,
      fixed: "height"
    };
  },
  computed: {
    isBmiOkay() {


      return this.bmi < 18.5
        ? "underweight"
        : this.bmi < 24.9
        ? "normalan"
        : this.bmi < 29.9 
        ? "prekomeran"
        : this.bmi < 34.9
        ? "Gojaznost I stepena"
         : this.bmi < 40
        ? "Gojaznost II stepena"
         : this.bmi < 99
        ? "Gojaznost III stepena"
        : "normal";
    }
  },
  methods: {
    calculateBMI() {
      this.bmi =
        Math.round((this.weight * 10000 * 10) / (this.height * this.height)) /
        10;
    },
    
    calcSelected() {
      if (this.fixed == "height") {
        this.weight =
          Math.round(((this.height * this.height * this.bmi) / 10000) * 10) /
          10;
      } else {
        this.height =
          Math.round(Math.sqrt((this.weight * 10000) / this.bmi) * 10) / 10;
      }
    }
  }
};
</script>

<style scoped>
.bmi-slider{display: none;}
.slider{width:100%;accent-color: #469c08;}
.rezultat{font-size:16px;text-align: center;margin-top:40px}
.cal-wrap {
display: flex;
    padding: 20px;
    flex-direction: column;
    position: relative;
    /* width: 300px; */
    gap: 10px;
    height: 360px;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 20px 50px 0 rgb(0, 0, 0, 0.1);
}
@media only screen and (max-width: 480px) {
  .cal-wrap {
    height: 370px;
  }
}
.underweight {
  color: #dbb900;
}

.normalan {
  color: green;
}
.prekomeran {
  color:  #f38303;
}
[class*="Gojaznost"]{
      color: red;
}
.Gojaznost I stepena {
  color: red;
}

.bmi-desc {
  font-style: oblique;
}
span {
  height: 1rem;
  margin: 0;
}
input {
  height: 1.5rem;
}
</style>
<template>
    <footer class="site-footer">
        <div class="top-footer">
            <div class="footer-overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center mb-5">
                        <a href="https://zdravaishrana.rs"> 
                        <img class="shadow footer-logo" alt="Zdrava Ishrana logo" src="../assets/zi_logo.png">
                        </a>
				<p class="fltext">
					Naši tekstovi su rezultat opsežnih istraživanja i znanstvenih radova u domenu pravilne ishrane.

Svaki korisnik ima pravo da sam proceni u kojoj će meri pratiti savete iz članaka na "Zdrava Ishrana" portalu. Želimo istaći da redovno praćenje naših preporuka može značajno doprineti postizanju željenih rezultata u oblasti ishrane.
				</p>
            </div>

                    <div class="col-lg-3 col-md-3">
                        <aside class="widget widget_text img-textwidget">
                           
                            <div class="textwidget widget-text">
                                <!-- Dostava zdravih obroka i sokova za mršavljenje i postizanje balansa organizma. -->
                            </div> 
                        </aside>
                        <div class="footer-social-links mt-5">
                            <ul class="mt-5">
                                <li><a href="#" target="_blank" class="social-icons" aria-label="Read more about us on Facebook"><i class="fab fa-facebook-square"></i></a> </li>
                                <li><a href="#" target="_blank" class="social-icons" aria-label="Folow us on Instagram"><i class="fab fa-instagram"></i></a> </li>
                                <li><a href="#" target="_blank" class="social-icons" aria-label="Watch us on YouTube"><i class="fab fa-youtube"></i></a> </li>
                                <li><a href="#" target="_blank" class="social-icons" aria-label="Watch us on TikTok"><i class="fab fa-tiktok"></i></a> </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <aside class="widget">
                            <div class="title-divider-right">Alati</div>
                            <ul>
                                
                    <li><a href="https://alati.zdravaishrana.rs/aditivi">Aditivi u hrani</a></li>
                    <li><a href="https://alati.zdravaishrana.rs/tk">Tablica Kalorija</a></li>
                    <li><a href="https://alati.zdravaishrana.rs/bmi">BMI Kalkulator</a></li>
                    <li><a href="https://alati.zdravaishrana.rs/bmr">BMR Kalkulator</a></li>
                    <li><a href="https://alati.zdravaishrana.rs/tdee">TDEE Kalkulator</a></li>
                    <li><a href="https://alati.zdravaishrana.rs/dpv">DPV Kalkulator</a></li>

                              
                            
                            </ul>
                        </aside>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <aside class="widget">
                            <div class="title-divider-right">Stranice</div>
                            <ul>
                                <li>
                                    <a href="https://zdravaishrana.rs/">Početna</a>
                                </li>
                                <li>
                                    <a href="https://zdravaishrana.rs/recepti">Recepti</a>
                                </li>
                                <li>
                                    <a href="https://zdravaishrana.rs/novosti">Novosti</a>
                                </li>
                                <li>
                                    <a href="https://zdravaishrana.rs/kontakt">Kontakt</a>
                                </li>
                                <li><a href="https://alati.zdravaishrana.rs/aditivi-list">Lista svih aditiva</a></li>

                              
                            </ul>
                        </aside>
                    </div>
                
                    <div class="col-lg-3 col-md-3">
                        <aside class="widget">
                            <div class="title-divider-right">Info</div>
                            <ul>
                    
                                <li>
                                    <router-link to="/kalorije">Šta su Kalorije?</router-link>
                                </li>
                                <li>
                                    <router-link to="/masti">Šta su Masti</router-link>
                                </li>
                                <li>
                                    <router-link to="/proteini">Šta su Proteini?</router-link>
                                </li>
                                <li>
                                    <router-link to="/uh">Šta su Ugljeni Hidrati?</router-link>
                                </li>
                            
                            </ul>
                        </aside>
                    </div>
                    <div class="seg-12 text-center copy mt-3">
               
               <div class="copy-txt mt-5">
                   Copyright 2024 <a href="https://zdravaishrana.rs" target="_blank">Zdrava Ishrana</a> | Developed by <a href="https://bbx.rs" target="_blank">Bytbix</a><br>

                   </div>
           </div>
                </div>
            </div>
        </div>
       
    </footer>
</template>
<script setup>
	

</script>